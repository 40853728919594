import { useAuth0 } from '@auth0/auth0-react';
import { httpMethod, useApi } from './useApi';

export type ResponseSchema = any;
export type RequestPOSTSchemaType = any;
export type RequestPUTSchemaType = any;
export type RequestDELETESchemaType = { id: string };

export const useCreateEmails = () => {
  const { user } = useAuth0();

  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'emails',
    httpMethod: httpMethod.POST,
    body: {},
    queryParams: {
      user_id: user.sub,
    },
  });

  const callFunction = (body: RequestPOSTSchemaType) => {
    call(body);
  };

  const responseData = data as ResponseSchema;
  return { call: callFunction, data: responseData, error, ...rest };
};

export const useGetEmails = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'emails',
    httpMethod: httpMethod.GET,
    body: {},
    queryParams: {},
  });

  const responseData = data as ResponseSchema;
  return { call, data: responseData, error, ...rest };
};

export const useUpdateEmails = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'emails',
    httpMethod: httpMethod.PUT,
    body: {},
    queryParams: {},
  });

  const callFunction = (body: RequestPUTSchemaType) => {
    call(body);
  };

  const responseData = data as ResponseSchema;
  return { call: callFunction, data: responseData, error, ...rest };
};

export const useDeleteEmails = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'emails',
    httpMethod: httpMethod.DELETE,
    body: {},
    queryParams: {},
  });

  const callFunction = (body: RequestDELETESchemaType) => {
    call(body);
  };

  const responseData = data as ResponseSchema;
  return { call: callFunction, data: responseData, error, ...rest };
};
