import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { usePostToken } from '../../networking/useAuth';
import { useCustomAuth } from './RelyantAuthProvider';

const Callback = () => {
  const { handleCustomAuthentication } = useCustomAuth();
  const { isLoading, statusCode, data, call } = usePostToken();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    console.log({ isLoading, statusCode, data });
    if (data && statusCode) {
      console.log(data);
      if (data.access_token && data.refresh_token) {
        handleCustomAuthentication(data);
        history.push('/');
      }
    }
  }, [data]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const state = params.get('state');

    console.log({ code, state });

    if (code) {
      call({ code, redirect_uri: window.location.origin + '/callback' });
    }
  }, []);

  return null;
};

export default Callback;
