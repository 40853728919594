import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import NavBar from './components/NavBar';
import Support from './views/Support/Support';
import history from './utils/history';

import './App.css';
import initFontAwesome from './utils/initFontAwesome';
import ResearchView from './views/ResearchView/ResearchView';
import ReportGenerationView from './views/ReportGeneration/ReportGeneration';
import ReviewView from './views/ReportReview/Review';
import ProfileView from './views/Profile/ProfileView';
import AdminView from './views/Admin/AdminView';
import EmailView from './views/EmailView/EmailView';
import Callback from './views/Login/Login';

initFontAwesome();

const App = () => {
  return (
    <Router history={history}>
      <div id="app">
        <NavBar>
          <div className="" style={{}}>
            <Switch>
              <Route path="/callback" isAuthenticated component={Callback} />
              <Route path="/" exact component={ReviewView} />
              <Route path="/email" component={EmailView} />
              <Route path="/profile" component={ProfileView} />
              <Route path="/report-generation" component={ReportGenerationView} />
              <Route path="/report-review" component={ReviewView} />
              <Route path="/research" component={ResearchView} />
              <Route path="/admin" component={AdminView} />
              <Route path="/support" component={Support} />
            </Switch>
          </div>
          {/* <Footer /> */}
        </NavBar>
      </div>
    </Router>
  );
};

export default App;
