import React, { ReactNode, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Dropdown, Layout, Menu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { FileDoneOutlined, FileSearchOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import logo from '../assets/logo.png';
import { useAuth0 } from '@auth0/auth0-react';
import { usePersistedState } from '../utils/usePersistedState';

const { Header, Sider } = Layout;

const NavBar = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const sidebarItems = [
    // {
    //   key: '1',
    //   icon: <EditOutlined />,
    //   link: 'report-generation',
    //   text: 'Report generation',
    // },
    {
      key: '2',
      icon: <FileDoneOutlined />,
      link: 'report-review',
      text: 'Review',
    },
    {
      key: '3',
      icon: <FileSearchOutlined />,
      link: 'research',
      text: 'Research',
    },
    {
      key: '4',
      icon: <FileSearchOutlined />,
      link: 'email',
      text: 'Email PoC',
    },
  ];

  const dropdDownItems: MenuProps['items'] = [
    {
      key: '1',
      label: 'Profile',
      style: { fontSize: 16 },
      onClick: () => {
        history.push('/profile');
        // window.location.href = '/profile';
      },
    },
    {
      key: '2',
      label: 'Logout',
      onClick: () => {
        sessionStorage.clear();
        logoutWithRedirect();
      },
      style: { fontSize: 16 },
    },
  ];
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = usePersistedState<boolean>('isNavBarCollapsed', false);

  const [current, setCurrent] = useState<string>(
    location.pathname === '/'
      ? sidebarItems[0].key
      : sidebarItems.filter((item) => `/${item.link}` === location.pathname).map((item) => item.key)[0]
  );
  const mode = 'vertical';
  const className = '';

  const { user, isAuthenticated, logout } = useAuth0();
  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  const toggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  // TODO: check the Admin role
  if (user && user.email === 'lucagiacomelli1604@gmail.com') {
    sidebarItems.push({
      key: '100',
      icon: null,
      link: 'admin',
      text: 'Admin',
    });
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider theme="light" trigger={null} collapsible collapsed={isCollapsed}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: 6,
            paddingBottom: 24,
          }}
        >
          {isCollapsed ? (
            <div style={{ padding: 12 }}>
              <Link to="/">
                <img width={'100%'} src={logo} alt="Logo" />
              </Link>
            </div>
          ) : (
            <div style={{ padding: 12 }}>
              <Link to="/">
                <img width={'80%'} src={logo} alt="Logo" />
              </Link>
            </div>
          )}
        </div>
        <Menu
          title={'Relyant'}
          theme="light"
          mode={mode}
          className={`${className} ant-menu-dropdown-${mode}`}
          defaultSelectedKeys={[]}
          selectedKeys={[current]}
        >
          {sidebarItems.map((item) => {
            return (
              <Menu.Item key={item.key} icon={item.icon} onClick={() => setCurrent(item.key)}>
                <Link to={item.link}>{item.text}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ background: '#fff', padding: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ paddingLeft: 16 }}>
              {isCollapsed ? (
                <MenuUnfoldOutlined className="trigger" onClick={toggle} />
              ) : (
                <MenuFoldOutlined className="trigger" onClick={toggle} />
              )}
            </div>
            {isAuthenticated && (
              <div style={{ paddingRight: 16 }}>
                <Dropdown menu={{ items: dropdDownItems }}>
                  <img src={user?.picture} alt="Profile" className="nav-user-profile rounded-circle" width={'50'} />
                </Dropdown>
              </div>
            )}
          </div>
        </Header>
        {children}
      </Layout>
    </Layout>
  );
};

export default NavBar;
