import { httpMethod, useApi } from './useApi';

export type ResponseSchema = { access_token: string; refresh_token: string };
export type RequestPOSTSchemaType = { code: string; redirect_uri: string };

export const usePostToken = () => {
  const { call, data, error, ...rest } = useApi({
    authenticated: false,
    path: 'auth/google-token',
    httpMethod: httpMethod.POST,
    body: {},
  });

  const callFunction = (body: RequestPOSTSchemaType) => {
    call(body);
  };

  const responseData = data as ResponseSchema;
  return { call: callFunction, data: responseData, error, ...rest };
};
