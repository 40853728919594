import React, { useEffect, useState } from 'react';
import { Avatar, Button, List, Skeleton } from 'antd';

export type ListItemEntry = {
  header: string;
  subheader?: string;
  content: string;
  href?: string;
  picture?: {
    large?: string;
    medium?: string;
    thumbnail?: string;
  };
  loading: boolean;
};

export const ListItems = ({ items }: { items: ListItemEntry[] }) => {
  //   const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  //   const [data, setData] = useState<DataType[]>([]);
  const [list, setList] = useState<ListItemEntry[]>(items);

  useEffect(() => {}, []);

  const onLoadMore = () => {
    setLoading(true);
    // setList(data.concat([...new Array(count)].map(() => ({ loading: true, header: '', subheader: '' }))));
    // fetch(fakeDataUrl)
    //   .then((res) => res.json())
    //   .then((res) => {
    //     const newData = data.concat(res.results);
    //     setData(newData);
    //     setList(newData);
    //     setLoading(false);
    //     // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
    //     // In real scene, you can using public method of react-virtualized:
    //     // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
    //     window.dispatchEvent(new Event('resize'));
    //   });
  };

  //   const loadMore =
  //     !initLoading && !loading ? (
  //       <div
  //         style={{
  //           textAlign: 'center',
  //           marginTop: 12,
  //           height: 32,
  //           lineHeight: '32px',
  //         }}
  //       >
  //         <Button onClick={onLoadMore}>loading more</Button>
  //       </div>
  //     ) : null;

  return (
    <List
      className="demo-loadmore-list"
      //   loading={initLoading}
      itemLayout="vertical"
      //   loadMore={loadMore}
      dataSource={list}
      renderItem={(item) => (
        // <List.Item actions={[<button key="list-loadmore-edit">view</button>]}>
        <List.Item actions={[]}>
          <Skeleton avatar title={false} loading={item.loading} active>
            <List.Item.Meta
              // avatar={<Avatar src={item.picture?.large} />}
              title={<a href={item.href}>{item.header}</a>}
              description={item.subheader}
            />
            {/* <div>{'Hello world'}</div> */}
            <div>{item.content}</div>
          </Skeleton>
        </List.Item>
      )}
    />
  );
};
