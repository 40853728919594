import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getConfig } from '../config';

export enum httpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export type BodyType = Record<string, string | number | any[]> | Record<string, string | number | any[]>[];

export type CallAPIProps = {
  authenticated: boolean;
  path: string;
  httpMethod: string;
  queryParams?: Record<string, string | string[]>;
  body?: BodyType;
  version?: number | string;
};

export type CallAPIResponse = {
  isLoading: boolean;
  data: unknown;
  error: string;
  message?: string;
  statusCode?: number;
  call: (data?: BodyType) => void;
};

const validateParams = ({ method, queryParams, bodyParams }) => {
  if (queryParams) {
    if (method !== httpMethod.GET) {
      throw Error('Query params only with GET requests');
    }
  }
};

const getRequestUrl = ({
  urlString,
  queryParams,
}: {
  urlString: string;
  queryParams?: Record<string, string | string[]>;
}) => {
  const url = new URL(urlString);
  if (queryParams) {
    Object.keys(queryParams).forEach((key) => {
      const qParamValue = queryParams[key];
      if (Array.isArray(qParamValue)) {
        const queryParamValues = qParamValue as string[];
        queryParamValues.forEach((param) => {
          url.searchParams.append(key, param);
        });
      } else {
        url.searchParams.append(key, qParamValue as string);
      }
    });
  }
  return url;
};

export const useApi = (apiProps?: CallAPIProps): CallAPIResponse => {
  const { apiOrigin } = getConfig();
  const [state, setState] = useState({
    isLoading: false,
    data: null,
    error: null,
    message: '',
    statusCode: null,
  });
  const { getAccessTokenSilently } = useAuth0();

  /**
   * Function to perform the actual network request
   * It validates the params against the HttpMethod,
   * prepares and performs the requests.
   */
  const call = async (body?: BodyType) => {
    setState({
      ...state,
      isLoading: true,
    });
    try {
      const bodyParams = { ...apiProps.body, ...body };

      validateParams({
        method: apiProps.httpMethod,
        queryParams: apiProps.queryParams,
        bodyParams: bodyParams,
      });

      const urlString = `${apiOrigin}api/v${apiProps.version || 1}/${apiProps?.path}`;
      const url = getRequestUrl({ urlString, queryParams: apiProps.queryParams });

      let headers: any = {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      };

      if (apiProps.authenticated) {
        const token = await getAccessTokenSilently();
        console.log(token);
        headers = {
          ...headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const response: Response = await fetch(url, {
        headers: headers,
        method: apiProps.httpMethod,
        body: apiProps.httpMethod !== httpMethod.GET ? JSON.stringify(bodyParams) : null,
      });
      let responseData = null;
      if (response.status < 300) {
        responseData = await response.json();
      }
      setState({
        ...state,
        data: responseData,
        statusCode: response.status || 413,
        isLoading: false,
      });
    } catch (error) {
      console.error({ error });
      setState({
        ...state,
        isLoading: false,
        statusCode: 500,
        error: error.error,
      });
    }
  };

  return { ...state, call };
};
