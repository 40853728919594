import React, { useEffect, useState } from 'react';

import Loading from '../../components/base/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Text } from '../../components/base/Text';
import { useGetEmails } from '../../networking/useEmails';
import { isNonEmpty } from '../../utils/arrayUtils';
import { ListItemEntry, ListItems } from '../../components/base/ListItems';

export const EmailView = () => {
  const { isLoading, statusCode, data: emails, call } = useGetEmails();
  const [emailListItems, setEmailListItems] = useState<ListItemEntry[]>([]);

  useEffect(() => {
    if (!emails) {
      call();
    }
  }, []);

  useEffect(() => {
    if (emails) {
      if (isNonEmpty(emails.items)) {
        const emailObjs: ListItemEntry[] = [];
        for (let item of emails.items) {
          emailObjs.push({
            header: item.subject,
            subheader: item.from,
            content: item.summary,
            loading: false,
          });
        }

        setEmailListItems(emailObjs);
      }
    }
  }, [emails]);

  console.log({ isLoading, statusCode, data: emails });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex-column" style={{ padding: '3vw', overflowY: 'auto', gap: 24 }}>
      <Text value={'Emails'} className="header1" />

      {emails && isNonEmpty(emailListItems) ? (
        // <div className="scrollable" style={{ minHeight: 200 }}>
        //   {emails.items.map((item, key) => {
        //     console.log(item.sender);
        //     return <div key={key}>{item.from}</div>;
        //   })}
        // </div>

        <ListItems items={emailListItems}></ListItems>
      ) : null}
    </div>
  );
};

export default withAuthenticationRequired(EmailView, {
  onRedirecting: () => <Loading />,
});
