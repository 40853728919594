import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { getConfig } from './config';
import { RelyantAuthProvider } from './views/Login/RelyantAuthProvider';

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const root = createRoot(document.getElementById('root'));
root.render(
  <RelyantAuthProvider>
    <App />
  </RelyantAuthProvider>
);
