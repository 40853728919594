/* eslint-disable react/display-name */
import React from 'react';

import { useCustomAuth } from './RelyantAuthProvider';

import { getConfig } from '../../config';

const config = getConfig();

/**
 * Alternative way to do authentication because we need to get the refresh token
 * on the backend, for refreshing the access token for the API calls.
 * @param Component
 * @returns
 */
const withRelyantAuthenticationRequired = (Component) => (props) => {
  const { isAuthenticated } = useCustomAuth();
  console.log({ isAuthenticated });

  if (!isAuthenticated) {
    const authUrl = `https://${config.domain}/authorize?response_type=code&client_id=${config.clientId}&redirect_uri=${encodeURIComponent(
      window.location.origin + '/callback'
    )}&scope=openid profile email offline_access&state=randomString&audience=${config.audience ? config.audience : null}`;
    window.location.href = authUrl;
    return null;
  }

  return <Component {...props} />;
};

export default withRelyantAuthenticationRequired;
