import React, { createContext, useContext, useState } from 'react';
import { Auth0Provider, LocalStorageCache } from '@auth0/auth0-react';
import { getConfig } from '../../config';

const CustomAuthContext = createContext({
  isAuthenticated: false,
  // user: null,
  handleCustomAuthentication: (tokens) => {},
});
const config = getConfig();

// eslint-disable-next-line react/prop-types
const RelyantAuthProvider = ({ children, ...props }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [customUser, setCustomUser] = useState(null);

  console.log({ isAuthenticated });

  const handleCustomAuthentication = (tokens) => {
    console.log({ tokens });
    const localStorageCache = new LocalStorageCache();
    localStorageCache.set('access_token', tokens.access_token);
    localStorageCache.set('refresh_token', tokens.refresh_token);
    localStorageCache.set('id_token', tokens.id_token);
    setIsAuthenticated(true);
    // TODO: fetch the user identity here
  };

  return (
    <CustomAuthContext.Provider value={{ isAuthenticated, handleCustomAuthentication }}>
      <Auth0Provider
        domain={config.domain}
        clientId={config.clientId}
        authorizationParams={{
          redirect_uri: window.location.origin + '/callback',
          audience: config.audience ? config.audience : null,
          scope: config.scope,
        }}
        skipRedirectCallback={true}
        // useRefreshTokens={true}
        // onRedirectCallback={onRedirectCallback}
      >
        {children}
      </Auth0Provider>
    </CustomAuthContext.Provider>
  );
};

const useCustomAuth = () => useContext(CustomAuthContext);

export { RelyantAuthProvider, useCustomAuth };
